import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.js';
// import { App } from './App';  // Use ES6 import instead of require
import reportWebVitals from './reportWebVitals.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n.js';
import { GoogleOAuthProvider } from '@react-oauth/google';

const GOOGLE_CLIENT_ID = '916977580664-tkfl69eridg63v6l0lq46fflkkarsflh.apps.googleusercontent.com';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
//       {/* <I18nextProvider i18n={i18n}>
//     <App />
//     </I18nextProvider> */}
//       <App />
//     </GoogleOAuthProvider>
//     ,
//   </React.StrictMode>
// );

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>

    <App />
    </GoogleOAuthProvider>,
  </React.StrictMode>
);

reportWebVitals();
